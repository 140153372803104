<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: calc(100vh - 34px);">
      <el-row type="flex">
        <el-col :span="12" :offset="1">
          <PageHeader :title="__('Messaging Detailed Log')"></PageHeader>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <el-form class="form-container">
            <PaginationToolbar
              :content-api="contentAPI"
              :key="`view-log-${task.task_id}`"
              :show-search="false"
              :show-total="false"
            >
            </PaginationToolbar>
          </el-form>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="22" :offset="1">
          <div class="contentListContainer">
            <el-table
              ref="detailedLogsTable"
              class="list-table"
              :data="detailedLogs"
              highlight-current-row
              v-loading="loading"
            >
              <el-table-column :label="__('Date')" prop="created_at">
                <template slot-scope="scope">
                  {{ formattedDateTime(scope.row.created_at) }}
                </template>
              </el-table-column>
              <el-table-column :label="__('Message Type')" prop="msg_type">
              </el-table-column>
              <el-table-column :label="__('Message Text')" prop="msg_txt">
              </el-table-column>
              <el-table-column :label="__('Segments')" prop="segments">
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { mapActions, mapState, mapGetters } from "vuex";
import { convertDurationInMicroSecondsToHumanReadableForm } from "@/utils/time";
export default {
  name: "DetailedLog",
  components: { PaginationToolbar, PageHeader },
  mixins: [BaseContent],
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState("usages", {
      detailedLogs: state => state.detailedLogs,
      loading: state => state.loading
    }),
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    convertMicroSecondsDurationToHumanReadableFormat() {
      return microSeconds =>
        convertDurationInMicroSecondsToHumanReadableForm(microSeconds);
    }
  },
  methods: {
    ...mapActions("usages", {
      contentAPI: "getDetailedMsgUsageLog",
      resetDetailedUsages: "resetDetailedUsages"
    })
  },
  created() {
    this.resetDetailedUsages();
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>

<style lang="scss">
.editContentItemModal {
  padding: 0;
  border-radius: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
